<template>
  <v-data-table
    must-sort
    fixed-header
    show-expand
    :loading="loading"
    :headers="tableHeaders"
    :items="prescriptions"
    item-key="uuid"
    :options.sync="options"
    :server-items-length="totalItems"
    :footer-props="{
      itemsPerPageOptions: [ 15, 25, 50, 100 ],
      showFirstLastPage: true,
    }"
    :items-per-page="15"
    no-data-text="No prescriptions found"
    class="elevation-2"
  >
    <template #top>
      <v-row class="pt-3 px-3 mb-n3">
        <v-col
          md="3"
          lg="2"
        >
          <search-bar :value.sync="options.search" />
        </v-col>
        <v-col cols="auto" />
        <v-col
          v-if="$auth.check({ clients: ['edit', 'view'] })"
          xmd="2"
        >
          <client-filter
            key="client-filter"
            :value.sync="options.filter.client"
            :params="{ has: 'prescriptions' }"
          />
        </v-col>
        <v-col v-if="$auth.check({ facilities: ['*', 'view', 'edit'] })">
          <facility-filter
            key="facility-filter"
            :value.sync="options.filter.facility"
            :params="{ has: 'prescriptions' }"
          />
        </v-col>
        <v-col xmd="2">
          <provider-filter
            key="provider-filter"
            :value.sync="options.filter.provider"
            :params="{ has: 'prescriptions' }"
          />
        </v-col>
        <v-col xmd="2">
          <drug-filter
            key="drug-filter"
            :value.sync="options.filter.drug"
            :params="{ has: 'prescriptions', quick: true }"
          />
        </v-col>
        <v-col xmd="2">
          <table-filter
            :value.sync="options.filter.route"
            :choices="$routes"
            type="routes"
            :label="$tc('route', 1) | capitalize"
            icon="fal fa-syringe"
            multiple
          />
        </v-col>
        <v-col xmd="2">
          <table-filter
            :value.sync="options.filter.cost"
            :choices="[
              { value:1, text:'$' },
              { value:2, text:'$$' },
              { value:3, text:'$$$' },
              { value:4, text:'$$$$' },
            ]"
            type="cost"
            label="Cost"
            icon="fal fa-dollar"
            small-chips
            multiple
          />
        </v-col>
        <v-col xmd="2">
          <date-filter
            :value.sync="options.filter.order_date"
            label="Order Date"
          />
        </v-col>
        <v-col xmd="2">
          <range-filter
            :value.sync="options.filter.duration"
            :label="$tc('duration', 1) | capitalize"
            item="day"
            icon="fal fa-calendar-range"
          />
        </v-col>
        <v-col xmd="2">
          <table-filter
            :value.sync="options.filter.flags"
            :choices="$flags"
            type="flags"
            label="Flags"
            icon="fal fa-flag"
            multiple
          />
        </v-col>
        <v-col xmd="2">
          <status-filter
            :value.sync="options.filter.status"
            type="prescriptions"
          />
        </v-col>
      </v-row>
    </template>
    <template #item.client="{ item }">
      <client-icon
        :key="item.client.uuid"
        :name="item.client.name"
        :icon="item.client.icon"
      />
    </template>
    <template #item.start_date="{ item }">
      <span class="text-caption">
        {{ item.start_date | moment('L LT') }}
      </span>
    </template>
    <template #item.end_date="{ item }">
      <span
        v-if="item.end_date"
        class="text-caption"
      >
        {{ item.end_date | moment('L LT') }}
      </span>
      <v-icon
        v-else
        color="grey lighten-1"
      >
        fal fa-horizontal-rule
      </v-icon>
    </template>
    <template #item.duration="{ item }">
      <template v-if="item.end_date">
        {{ item.duration | pluralize('day', { includeNumber: true }) }}
      </template>
      <v-icon
        v-else
        small
      >
        fal fa-exclamation-triangle
      </v-icon>
    </template>
    <template #item.patient="{ item }">
      <span data-private>
        <patient-name
          :key="item.uuid"
          :patient="item.patient"
          first-initial
        />
        <v-tooltip
          v-if="$moment(item.created_at).diff(item.patient?.dob, 'years') < 18"
          top
          small
        >
          <template #activator="{ on }">
            <v-icon
              small
              color="orange"
              class="ml-1"
              v-on="on"
            >
              fas fa-child
            </v-icon>
          </template>
          Pediatric Patient
        </v-tooltip>
      </span>
    </template>
    <template #item.provider="{ item }">
      <template v-if="item.provider">
        {{ item.provider.first_name.charAt(0) }}. {{ item.provider.last_name }}
        <v-icon
          v-if="item.created_at > item.provider.advanced_asp"
          key="advanced_asp_icon"
          color="cyan"
          size="14"
          title="Advanced ASP Provider"
        >
          fas fa-star
        </v-icon>
      </template>
      <v-icon
        v-else
        color="grey lighten-1"
      >
        fal fa-horizontal-rule
      </v-icon>
    </template>
    <template #item.facility="{ item }">
      <template v-if="item.facility">
        {{ item.facility.name | truncate(18) }}
      </template>
      <v-icon
        v-else
        color="grey lighten-1"
      >
        fal fa-horizontal-rule
      </v-icon>
    </template>
    <template #item.dosage="{ item }">
      {{ item.dosage }}
      {{ item.route }}
      {{ item.interval }}
    </template>
    <template #item.price="{ item }">
      <drug-price
        v-if="item.cost"
        :key="`drug-price-${item.cost}`"
        :price="item.cost"
      />
    </template>
    <template #item.status="{ item }">
      <status-chip
        :key="item.status"
        :status="item.status"
        type="prescriptions"
      />
    </template>
    <template #expanded-item="{ item }">
      <td :colspan="tableHeaders.length">
        <v-row class="my-0">
          <v-col>
            <v-list-item selectable>
              <v-list-item-content>
                <v-list-item-subtitle>UUID</v-list-item-subtitle>
                <v-list-item-title v-text="item.uuid" />
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col>
            <v-list-item
              v-if="item.provider"
              selectable
            >
              <v-list-item-icon class="mr-4">
                <v-icon>
                  fal fa-fw fa-user-md
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>Provider</v-list-item-subtitle>
                <v-list-item-title>{{ item.provider.first_name }} {{ item.provider.last_name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="item.facility"
              selectable
            >
              <v-list-item-icon class="mr-4">
                <v-icon>
                  fal fa-fw fa-hospital
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>Facility</v-list-item-subtitle>
                <v-list-item-title v-text="item.facility.name" />
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col v-if="$auth.check({ patients: 'phi' })">
            <v-list-item selectable>
              <v-list-item-icon class="mr-4">
                <v-icon data-private>
                  fal fa-fw fa-{{ item.patient?.gender }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>Patient Name</v-list-item-subtitle>
                <v-list-item-title data-private>
                  {{ item.patient?.first_name }} {{ item.patient?.last_name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-icon class="mr-4">
                <v-icon>
                  fal fa-fw fa-birthday-cake
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>Date of Birth</v-list-item-subtitle>
                <v-list-item-title data-private>
                  {{ item.patient?.dob | moment('L') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </td>
    </template>
  </v-data-table>
</template>
<script>
  import Vue2Filters from 'vue2-filters'
import { mapMutations } from 'vuex'

  export default {
    components: {
      SearchBar: () => import('@/components/tables/filters/SearchBar.vue'),
      ClientFilter: () => import('@/components/tables/filters/ClientFilter.vue'),
      DateFilter: () => import('@/components/tables/filters/DateFilter.vue'),
      DrugFilter: () => import('@/components/tables/filters/DrugFilter.vue'),
      ProviderFilter: () => import('@/components/tables/filters/ProviderFilter.vue'),
      FacilityFilter: () => import('@/components/tables/filters/FacilityFilter.vue'),
      TableFilter: () => import('@/components/tables/filters/TableFilter.vue'),
      RangeFilter: () => import('@/components/tables/filters/RangeFilter.vue'),
      StatusFilter: () => import('@/components/tables/filters/StatusFilter.vue'),
      ClientIcon: () => import('@/components/ClientIcon.vue'),
      PatientName: () => import('@/components/patient/PatientName.vue'),
      StatusChip: () => import('@/components/StatusChip.vue'),
      DrugPrice: () => import('@/components/drug/DrugPrice.vue'),
    },
    mixins: [
      Vue2Filters.mixin,
    ],
    props: {
      search: {
        type: String,
        default: null,
      },
    },
    metaInfo: {
      title: 'Prescriptions',
    },
    data () {
      return {
        pdf: null,
        files: {},
        loading: false,
        options: this.$store.state.filters.prescriptions ? this.$store.state.filters.prescriptions : {},
        totalItems: null,
        headers: [
          {
            text: 'Client',
            value: 'client',
            sortable: false,
          },
          {
            text: 'Order ID',
            value: 'external_id',
          },
          {
            text: 'Patient',
            value: 'patient',
            sortable: false,
          },
          {
            text: 'Provider',
            value: 'provider',
            sortable: false,
          },
          // {
          //   text: 'Facility',
          //   value: 'facility',
          //   sortable: false,
          // },
          {
            text: 'Start',
            value: 'start_date',
          },
          {
            text: 'End',
            value: 'end_date',
          },
          {
            text: 'Duration',
            value: 'duration',
          },
          {
            text: 'Drug',
            value: 'drug.name',
          },
          {
            text: 'Dosage',
            value: 'dosage',
          },
          {
            text: 'Price',
            value: 'price',
            sortable: false,
          },
        //   {
        //     text: 'Resistance',
        //     value: 'resistance_genes_count',
        //     align: 'center',
        //   },
          {
            text: 'Status',
            value: 'status',
            sortable: false,
          },
          {
            sortable: false,
            align: 'right',
            value: 'actions',
          },
        ],
        prescriptions: [],
        searchDelay: false,
      }
    },
    computed: {
      tableHeaders () {
        var headers = this.headers
        if (!this.$auth.check({ clients: ['view','edit'] })) {
          headers = headers.filter(x => x.value && x.value != 'client')
        }
        return headers
      },
    },
    watch: {
      '$route.params.filter': {
        deep: true,
        handler () {
          this.options.filter = this.$route.params.filter
        },
      },
      options: {
        deep: true,
        handler (options) {
          this.setFilterPrescriptions(options)
          if (!this.searchDelay) {
            setTimeout(() => {
              this.fetchPrescriptions()
              this.searchDelay = false
            }, 800)
          }
          this.searchDelay = true
        },
      },
    },
    mounted () {
      this.options.filter = {
        ...this.options.filter,
        ...this.$route.query,
      }
    },
    methods: {
      ...mapMutations('filters', ['setFilterPrescriptions']),
      fetchPrescriptions () {
        this.loading = true
        const promise = this.axios.get('prescriptions', {
          params:
            {
              ...{
                page: this.options.page,
                count: this.options.itemsPerPage,
                sort: this.options.sortBy[0],
                order: this.options.sortDesc[0] ? 'desc' : 'asc',
                search: this.options.search,
                },
              ...this.options.filter,
            },
        })

        return promise.then((response) => {
          if (response.data.last_page < response.data.current_page) {
            this.options.page = response.data.last_page
            this.fetchPrescriptions()
            return
          }
          this.prescriptions = response.data.data
          this.totalItems = response.data.total
          this.options.page = response.data.current_page
          this.options.itemsPerPage = Number(response.data.per_page)
        }).catch(error => {
          this.$toast.error(error)
        }).finally(() => {
          this.loading = false
        })
      },
      deletePrescription (item) {
        this.$dialog.prompt({
          icon: 'fal fa-trash',
          title: 'Delete prescription?',
          text: 'Enter the word DELETE to confirm',
        })
          .then(confirm => {
            if (confirm === 'DELETE') {
              this.axios
                .delete('prescriptions/' + item.uuid)
                .then(() => {
                  const index = this.prescriptions.indexOf(item)
                  this.$delete(this.prescriptions, index)
                  this.totalItems--
                })
            }
          })
      },
      uploadInterpretationPDF (item) {
        const formData = new FormData()
        formData.append('interpretation', this.files[item.uuid], this.files[item.uuid].name)
        formData.append('_method', 'patch')
        this.axios
          .post('prescriptions/' + item.uuid + '/interpretation', formData)
          // .then(response => {
          //   const index = this.prescriptions.indexOf(item)
          //   this.$delete(this.prescriptions, index)
          //   this.options.totalItems--
          // })
      },
      fetchInterpretationPDF (item, redacted = false) {
        this.axios.get('/prescriptions/' + item.uuid + '/interpretation/pdf?output=base64&redacted=' + redacted)
          .then((response) => {
            const link = document.createElement('a')
            link.href = 'data:application/pdf;base64,' + response.data.report_content
            link.setAttribute('download', item.external_id + ' - ' + item.sample_type + ' - ' + item.patient.first_name + ' ' + item.patient.last_name + ' - Arkstone.pdf')
            document.body.appendChild(link)
            link.click()
            link.remove()
          })
          .catch(error => {
            this.$toast.error(error)
          })
      },
    },
  }
</script>
